.statsBlock_container {
  width: 200px;
  height: 200px;
  padding: 15px;
  margin: 15px 5px;
  padding: 40px 0;

  i {
    color: black;
    font-size: 48px;
  }
  .bigNumber {
    color: black;
    display: block;
    font-weight: bold;
    letter-spacing: 0;
    font-size: 16px;
    line-height: 24px;
  }
  h4 {
    font-family: $fontTitle;
    color: $colorText;
    letter-spacing: 0.15em;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
  }
}